import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './IframeVideoBlock.module.scss';

function IframeVideoBlock(props) {
  const { block: { iframeCode } } = props;

  if (!iframeCode) return null;

  return (
    <div className={[styles.iframeVideoBlock, 'container-fluid'].join(' ')}>
      <div className="row">
        <div className="col-12">
          <div className={styles.videoWrapOuter}>
            <div className={styles.videoWrapInner}>
              <div
                className={styles.embedContainer}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: iframeCode }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

IframeVideoBlock.propTypes = {
  block: PropTypes.shape({
    iframeCode: PropTypes.string.isRequired,
  }).isRequired,
};
export default IframeVideoBlock;
