// Structure for single journal page
const getSingleJournalUrl = (slug) => {
  if (!slug) return '/';

  return `/journal/${slug}`;
};

// Category archive url
const getCategoryArchiveUrl = (slug) => {
  if (!slug) return '/';

  return `/category/${slug}`;
};

// URL for type archive page
const getTypeArchiveUrl = (slug) => {
  if (!slug) return '/';

  return `/${slug}`;
};
const permalinks = {
  getSingleJournalUrl,
  getCategoryArchiveUrl,
  getTypeArchiveUrl,
};
export { getSingleJournalUrl, getCategoryArchiveUrl, getTypeArchiveUrl };
export default permalinks;
