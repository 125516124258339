/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import DesktopImage from './ContentBlocks/DesktoppImage/DesktopImage';
import MobileImage from './ContentBlocks/MobileImage/MobileImage';
import TextBlock from './ContentBlocks/TextBlock/TextBlock';
import VideoBlock from './ContentBlocks/VideoBlock/VideoBlock';
import IframeVideoBlock from './ContentBlocks/IframeVideoBlock/IframeVideoBlock';
import AutoplayVideoBlock from './ContentBlocks/AutoplayVideoBlock/AutoplayVideoBlock';

function PageContent(props) {
  const {
    content: {
      contentBlock,
    },
  } = props;

  const renderContent = () => {
    if (!contentBlock || !contentBlock.length) return null;
    return contentBlock.map((block, index) => {
      switch (block.fieldGroupName) {
        case 'Journal_Journalcontent_ContentBlock_DesktopImage':
        case 'DefaultTemplate_Pagecontent_ContentBlock_DesktopImage':
          // desktop image
          return <DesktopImage key={index} block={block} />;
        case 'Journal_Journalcontent_ContentBlock_MobileImage':
        case 'DefaultTemplate_Pagecontent_ContentBlock_MobileImage':
          // mobile image
          return <MobileImage key={index} block={block} />;
        case 'Journal_Journalcontent_ContentBlock_TextBlock':
        case 'DefaultTemplate_Pagecontent_ContentBlock_TextBlock':
          // text block
          return <TextBlock key={index} block={block} />;
        case 'Journal_Journalcontent_ContentBlock_VideoBlock':
        case 'DefaultTemplate_Pagecontent_ContentBlock_VideoBlock':
          // video
          return <VideoBlock key={index} block={block} />;
        case 'Journal_Journalcontent_ContentBlock_IframeVideoBlock':
        case 'DefaultTemplate_Pagecontent_ContentBlock_IframeVideoBlock':
          // video
          return <IframeVideoBlock key={index} block={block} />;
        case 'Journal_Journalcontent_ContentBlock_AutoplayVideoBlock':
        case 'DefaultTemplate_Pagecontent_ContentBlock_AutoplayVideoBlock':
          // autoplay video
          return <AutoplayVideoBlock key={index} block={block} />;
        default:
          return <h2 key={index}>Unknown field group</h2>;
      }
    });
  };

  return <>{renderContent()}</>;
}

PageContent.propTypes = {
  content: PropTypes.shape({
    fieldGroupName: PropTypes.string.isRequired,
    contentBlock: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default PageContent;
