import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './TextBlock.module.scss';

function TextBlock(props) {
  const { block: { description } } = props;

  if (!description) return null;

  return (
    <div className={[styles.textBlock, 'container-fluid'].join(' ')}>
      <div className="row">
        <div className="col-12 col-md-6">
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  );
}

TextBlock.propTypes = {
  block: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
};

export default TextBlock;
