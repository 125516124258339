import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from './DesktopImage.module.scss';

function DesktopImage(props) {
  const { block: { image, caption, linkUrl } } = props;

  const renderCaption = () => {
    if (!caption) return null;

    // eslint-disable-next-line react/no-danger
    return <div className={styles.caption} dangerouslySetInnerHTML={{ __html: caption }} />;
  };

  const renderBlock = () => {
    if (!image) return null;

    let imageMarkup = (
      <GatsbyImage
        image={getImage(image.localFile.childImageSharp)}
        alt={image.altText}
        className={styles.gatsbyImageWrap}
        imgClassName={styles.desktopImage}
        objectFit="contain"
        objectPosition="0% 0%"
      />
    );

    if (linkUrl) {
      imageMarkup = <Link to={linkUrl}>{imageMarkup}</Link>;
    }

    return (
      <div className={[styles.desktopImageBlock, 'container-fluid d-none d-md-block'].join(' ')}>
        <div className="row">
          <div className="col-12">
            {imageMarkup}
            {renderCaption()}
          </div>
        </div>
      </div>
    );
  };
  return renderBlock();
}

DesktopImage.defaultProps = {
  block: {
    image: null,
    caption: null,
    linkUrl: null,
  },
};
DesktopImage.propTypes = {
  block: PropTypes.shape({
    image: PropTypes.shape({
      altText: PropTypes.string.isRequired,
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({}).isRequired,
      }).isRequired,
    }).isRequired,
    caption: PropTypes.string,
    linkUrl: PropTypes.string,
  }).isRequired,
};
export default DesktopImage;
