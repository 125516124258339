import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from './MobileImage.module.scss';

const MobileImage = (props) => {
  const { block: { image, caption, linkUrl } } = props;

  const renderCaption = () => {
    if (!caption) return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.caption} dangerouslySetInnerHTML={{ __html: caption }} />
          </div>
        </div>
      </div>
    );
  };

  const renderBlock = () => {
    if (!image) return null;

    let imageMarkup = (
      <GatsbyImage
        image={getImage(image.localFile.childImageSharp)}
        alt={image.altText}
      />
    );

    if (linkUrl) {
      imageMarkup = <Link to={linkUrl}>{imageMarkup}</Link>;
    }

    return (
      <div className={[styles.mobileImageBlock, 'd-md-none'].join(' ')}>
        {imageMarkup}
        {renderCaption()}
      </div>
    );
  };
  return renderBlock();
};

MobileImage.defaultProps = {
  block: {
    image: null,
    caption: null,
    linkUrl: null,
  },
};
MobileImage.propTypes = {
  block: PropTypes.shape({
    image: PropTypes.shape({
      altText: PropTypes.string.isRequired,
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({}).isRequired,
      }).isRequired,
    }).isRequired,
    caption: PropTypes.string,
    linkUrl: PropTypes.string,
  }).isRequired,
};
export default MobileImage;
