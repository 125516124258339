import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './Breadcrumbs.module.scss';

function Breadcrumbs(props) {
  const { data } = props;

  const renderBreadCrumbs = () => {
    if (!data || !data.length) return null;

    return (
      <div className={styles.breadcrumbsWrap}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul className={styles.breadcrumbsList}>
                {data.map((item, index) => {
                  let listItem = item.label;
                  if (item.url) {
                    listItem = <Link to={item.url}>{listItem}</Link>;
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  listItem = <li key={index}>{listItem}</li>;
                  return listItem;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderBreadCrumbs();
}

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string.isRequired,
  })),
};
export default Breadcrumbs;
