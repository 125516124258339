import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import * as styles from './VideoBlock.module.scss';

function VideoBlock(props) {
  const {
    block: {
      mobileVideoUrl, desktopVideoUrl, posterImage,
    },
  } = props;

  const [isDesktop, setIsDesktop] = useState(false);
  const matches = useMediaQuery('only screen and (min-width: 768px)');

  /* Add `matches` to dependency if we want to switch initial media after resize */
  useEffect(() => {
    setIsDesktop(matches);
  }, [isDesktop]);

  const renderVideo = () => {
    const attributes = {
      playsInline: true,
      src: mobileVideoUrl,
      className: styles.videoPlayer,
    };
    if (posterImage && posterImage?.localFile?.url) {
      attributes.poster = posterImage.localFile.url;
    }

    if (isDesktop && desktopVideoUrl) {
      attributes.src = desktopVideoUrl;
    }
    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Player {...(attributes)}>
          <BigPlayButton position="center" />
          <ControlBar />
        </Player>
      </>
    );
  };
  return (
    <div className={[styles.videoBlock, 'container-fluid'].join(' ')}>
      <div className="row">
        <div className="col-12">
          <div className={styles.videoWrapOuter}>
            <div className={styles.videoWrapInner}>
              {renderVideo()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

VideoBlock.propTypes = {
  block: PropTypes.shape({
    mobileVideoUrl: PropTypes.string.isRequired,
    desktopVideoUrl: PropTypes.string,
    posterImage: PropTypes.shape({
      localFile: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};
export default VideoBlock;
