import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import usePageStyles from '../hooks/usePageStyles';
import HeaderSpacer from '../components/Helpers/HeaderSpacer';
import PageContent from '../components/PageContent/PageContent';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { getTypeArchiveUrl } from '../utilities/permalinks';

function Journal(props) {
  const {
    data: { wpJournal },
    location,
  } = props;

  // setup breadcrumbs
  const {
    journalContent,
    journalMenuTitle,
    types: { nodes: types },
  } = wpJournal;
  const { menuTitle } = journalMenuTitle || { menuTitle: null };
  const breadCrumbs = [];

  if (location && location?.state && location.state?.breadcrumbs) {
    // extract breadcrumbs from state if exists
    breadCrumbs.push(...location.state.breadcrumbs);
  } else if (types && types.length) {
    const type = types.find(() => true);
    breadCrumbs.push({ url: getTypeArchiveUrl(type.slug), label: type.name });
  }
  if (menuTitle) {
    breadCrumbs.push({ label: menuTitle });
  } else {
    breadCrumbs.push({ label: wpJournal.title });
  }

  usePageStyles({ backgroundColor: '#fff' });

  return (
    <>
      <Seo post={wpJournal} />
      <HeaderSpacer />
      <Breadcrumbs data={breadCrumbs} />
      <PageContent content={journalContent} />
    </>
  );
}
export default Journal;

Journal.propTypes = {
  data: PropTypes.shape({
    wpJournal: PropTypes.shape({
      title: PropTypes.string.isRequired,
      journalMenuTitle: PropTypes.shape({
        menuTitle: PropTypes.string,
      }),
      types: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
      }),
      journalContent: PropTypes.shape({
        contentBlock: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export const query = graphql`
  query journalQuery($journalId: String) {
    wpJournal(id: { eq: $journalId }) {
      seo {
        ...YoastPageSEOContent
      }
      title
      journalMenuTitle {
        fieldGroupName
        menuTitle
      }
      types {
        nodes {
          id
          slug
          name
        }
      }
      ...WpJournalContentFragment
    }
  }
`;
