import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import { Player, ControlBar } from 'video-react';
import * as styles from './AutoplayVideoBlock.module.scss';

function AutoplayVideoBlock(props) {
  const {
    block: {
      mobileVideoUrl, desktopVideoUrl,
    },
  } = props;

  const [isDesktop, setIsDesktop] = useState(false);
  const matches = useMediaQuery('only screen and (min-width: 768px)');

  /* Add `matches` to dependency if we want to switch initial media after resize */
  useEffect(() => {
    setIsDesktop(matches);
  }, [isDesktop]);

  const renderVideo = () => {
    const attributes = {
      playsInline: true,
      src: mobileVideoUrl,
      className: styles.videoPlayer,
      autoPlay: true,
      muted: true,
      loop: true,
    };

    if (isDesktop && desktopVideoUrl) {
      attributes.src = desktopVideoUrl;
    }
    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Player {...(attributes)}>
          <ControlBar disableCompletely />
        </Player>
      </>
    );
  };

  return (
    <div className={[styles.autoplayVideoBlock, 'container-fluid'].join(' ')}>
      <div className="row">
        <div className="col-12">
          <div className={styles.videoWrapOuter}>
            <div className={styles.videoWrapInner}>
              {renderVideo()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
AutoplayVideoBlock.propTypes = {
  block: PropTypes.shape({
    mobileVideoUrl: PropTypes.string.isRequired,
    desktopVideoUrl: PropTypes.string,
  }).isRequired,
};

export default AutoplayVideoBlock;
